<template>
    <section>
        <div id="uploadModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-large">Upload Template Premium via Disburse</h2>
                </div>
                <form @submit.prevent="submitUpload">
                    <div class="uk-padding-small uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label for="company" class="uk-form-label uk-text-default">Company <span class="uk-text-danger">*</span></label>
                            <multiselect 
                                id="company" 
                                :multiple="false" 
                                :searchable="true" :internal-search="false"
                                :loading='isFetchingCompany' 
                                :options="companyOptions" 
                                track-by="_id" label="name"
                                placeholder="Select Company" 
                                data-vv-name="company_id" 
                                data-vv-scope="form"
                                v-validate="{ required: true }" 
                                v-model="form.company_id"
                                @search-change="filterCompanyByName" 
                            />
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form.company_id')">
                                {{ errors.first('form.company_id') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1">
                            <label for="pic" class="uk-form-label uk-text-default">PIC Upload <span class="uk-text-danger">*</span></label>
                            <multiselect 
                                id="pic" 
                                :multiple="true" 
                                :close-on-select="false" 
                                :options="picOptions"
                                :loading='isFetchingPic' 
                                track-by="_id" 
                                label="fullname" 
                                placeholder="Select PIC"
                                data-vv-name="pic_ids" 
                                data-vv-scope="form" 
                                v-validate="{ required: true, min: 1 }"
                                v-model="form.pic_ids" 
                                @search-change="filterPicByFullname" 
                            />
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form.pic_ids')">
                                {{ errors.first('form.pic_ids') }}
                            </span>
                        </div>
                        <div class="uk-width-1-1 uk-margin">
                            <label for="fileUpload" class="uk-form-label uk-text-default">File <span class="uk-text-danger">*</span></label>
                            <input 
                                ref="fileUpload" 
                                id="fileUpload" 
                                name="fileUpload" 
                                readonly type="file" hidden
                                accept=".xls,.xlsx" 
                                data-vv-name="file" 
                                data-vv-scope="form" 
                                v-validate="{ required: true }"
                                @change="handleFileChange" 
                            />
                            <button type="button" class="uk-input uk-border-rounded uk-text-left" :class="[!form.file ? 'uk-text-muted' : '']" @click="openFileUpload">
                                {{ form.file || 'File name' }}
                            </button>
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('form.file')">
                                {{ errors.first('form.file') }}
                            </span>
                        </div>
                    </div>

                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <section>
                            <button class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary" type="button" style="border-color: #0f7ae5;" @click="hideModal(false)">
                                Cancel
                            </button>
                            <button class="uk-button uk-border-rounded" type="submit" :class="[disableSaveButton ? 'uk-button-default' : 'uk-button-primary']" :disabled="disableSaveButton">
                                Upload
                            </button>
                        </section>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data: () => ({
        form: {
            company_id: null,
            pic_ids: [],
            file: null,
        },
        metaPic: {
            name: '',
            roles: 'admin-operation'
        },
        metaCompany: { name: '' },
        companyOptions: [],
        picOptions: [],
        isFetchingCompany: false,
        isFetchingPic: false,
        document: null,
        isUploading: false,
    }),
    watch: {
        async "metaPic.name"() {
            try {
                if (this.metaPic.name.length < 3) {
                    return;
                }

                this.isFetchingPic = true;
                await this.setPicOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingPic = false;
            }
        },
        async "metaCompany.name"() {
            try {
                if (this.metaCompany.name.length < 3) {
                    return;
                }

                this.isFetchingCompany = true;
                await this.setCompanyOptions();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingCompany = false;
            }
        },
    },
    computed: {
        disableSaveButton() {
            return (
                !this.form.company_id ||
                !this.form.pic_ids.length ||
                !this.form.file
            ) || this.errors.any();
        },
        payload() {
            const formData = new FormData();

            if (this.form.company_id?._id) formData.append('company_id', this.form.company_id._id);
            if (this.form.pic_ids.length) formData.append('pic_ids', this.form.pic_ids.map(pic => pic._id).join(','));
            if (this.form.file && this.document) formData.append('excel', this.document);

            // formData.
            return formData;
        },
    },
    async mounted() {
        window.UIkit.modal('#uploadModal').show();

        try {
            await Promise.all([
                this.setCompanyOptions(),
                this.setPicOptions(),
            ]);
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            uploadTemplate: 'membershipV4/uploadMembershipTemplate',
            getUserOptionList: 'userV4/getUserOptionList',
            getCompanyOptionList: 'companyV4/getCompanyOptionList',
        }),
        async setPicOptions() {
            try {
                this.isFetchingPic = true;
                const response = await this.getUserOptionList(this.metaPic);
                if (response?.data?.status === 'OK' && response?.data?.result) {
                    this.picOptions = response.data.result || [];
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingPic = false;
            }
        },
        async setCompanyOptions() {
            try {
                this.isFetchingCompany = true;
                const response = await this.getCompanyOptionList(this.metaCompany);
                if (response?.data?.status === 'OK' && response?.data?.result) {
                    this.companyOptions = response.data.result || [];
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isFetchingCompany = false;
            }
        },
        async submitUpload() {
            try {
                const payload = this.payload;
                this.isUploading = true;
                const response = await this.uploadTemplate(payload);

                if (response?.status !== 'OK' || !response?.result) {
                    throw new Error(response?.message || 'Failed to upload template');
                }

                notificationSuccess("Success upload template");
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isUploading = false;
                this.hideModal(true);
            }
        },
        handleFileChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.document = file;
                this.form.file = file.name;
            }
        },
        hideModal(isRefresh) {
            this.resetForm();
            window.UIkit.modal('#uploadModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        resetForm() {
            this.form.company_id = null;
            this.form.pic_ids = [];
            this.form.file = null;

            this.$validator.reset();
        },
        openFileUpload() {
            this.$refs.fileUpload.click();
        },
        filterPicByName(query) {
            if (query.length < 3) {
                return;
            }

            this.metaPic.name = query;
        },
        filterCompanyByName(query) {
            if (query.length < 3) {
                return;
            }

            this.metaCompany.name = query;
        },
    }
};
</script>
